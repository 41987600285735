import { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { InputAdornment } from '@mui/material';
import './style.scss';

export default function SearchBar({ onSearchChange }) {
  const [keyword, setKeyword] = useState('');

  const handleChange = (event) => {
    setKeyword(event.target.value);
    onSearchChange(event.target.value);
  };

  return (
    <div className="search-bar-wrapper">
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          className="input-field"
          value={keyword}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

SearchBar.propTypes = {
  onSearchChange: PropTypes.func,
};
