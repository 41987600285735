import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import PanelSection from '../PanelSection';
import './style.scss';

export default function ViewOptionsPanel({ viewOptions, onViewOptionsChange }) {
  const selectedData = {};

  Object.keys(viewOptions).forEach((key, val) => {
    selectedData[key] = viewOptions[key].selected || viewOptions[key].data[0];
  });
  onViewOptionsChange(selectedData);

  const getDefaultOption = (value) => {
    const data = viewOptions[value].data;
    const selectedValue = viewOptions[value].selected;
    return data.find(option => option.value === selectedValue);
  }

  const onValueChange = (key, val) => {
    selectedData[key] = val;
    onViewOptionsChange(selectedData);
  }

  const isVisible = (value) => {
    return Boolean(viewOptions[value]);
  }

  if (!Object.keys(viewOptions).length) return null;

  return (
    <div className="view-options-panel-wrapper">
      <PanelSection title="View options">
        <div className="panel-row">

          {
            isVisible('chartType') &&
            <Dropdown
              options={viewOptions['chartType'].data}
              defaultOption={getDefaultOption('chartType')}
              label={'Chart Type'}
              hasSmallWidth={true}
              hasSmallHeight={true}
              onChangeItem={(option) => onValueChange('chartType', option.value)}
            />
          }

          {
            isVisible('interval') &&
            <Dropdown
              options={viewOptions['interval'].data}
              defaultOption={getDefaultOption('interval')}
              info="info"
              label={'Interval'}
              hasSmallWidth={true}
              hasSmallHeight={true}
              onChangeItem={(option) => onValueChange('interval', option)}
            />
          }
        </div>

        <div className="panel-row">
          {
            isVisible('unitConversion') &&
            <Dropdown
              options={viewOptions['unitConversion'].data}
              defaultOption={getDefaultOption('unitConversion')}
              label={'Unit Conversion'}
              hasSmallWidth={true}
              hasSmallHeight={true}
              onChangeItem={(option) => onValueChange('unitConversion', option)}
            />
          }

          {
            isVisible('emissionsCost') &&
            <Dropdown
              options={viewOptions['emissionsCost'].data}
              defaultOption={getDefaultOption('emissionsCost')}
              label={'Emissions/Cost'}
              hasSmallWidth={true}
              hasSmallHeight={true}
              onChangeItem={(option) => onValueChange('emissionsCost', option)}
            />
          }
        </div>

        <div className="panel-row">
          {
            isVisible('normalization') &&
            <Dropdown
              options={viewOptions['normalization'].data}
              defaultOption={getDefaultOption('normalization')}
              label={'Normalize'}
              info="info"
              onChangeItem={(option) => onValueChange('normalization', option)}
              hasSmallHeight={true}
            />
          }
        </div>
      </PanelSection>
    </div>
  );
}

ViewOptionsPanel.propTypes = {
  viewOptions: PropTypes.object,
  onViewOptionsChange: PropTypes.func
};
