import { Children, useState } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import ErrorBoundary from '../ErrorBoundary';
import LanguageContext from './LanguageContext';

import enTranslationMessages from '../../translations/en.json';
import deTranslationMessages from '../../translations/de.json';
import frTranslationMessages from '../../translations/fr.json';

if (!Intl.PluralRules) {
  require('intl-pluralrules');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
  // require('@formatjs/intl-relativetimeformat/dist/include-aliases');
  require('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for en
}

const DEFAULT_LOCALE = 'en';

const appLocales = [
  'en',
  'de',
  'fr'
];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
    : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

const messages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  de: formatTranslationMessages('de', deTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages)
};

export default function LanguageProvider({ children }) {
  const [locale, setLocale] = useState(window.sessionStorage.getItem('locale') || 'en');

  function updateLocale(localeParam) {
    if (localeParam !== locale) {
      window.sessionStorage.setItem('locale', localeParam);
      setLocale(localeParam);
    }
  }

  return (
    <ErrorBoundary componentKey="LanguageProvider">
      <LanguageContext.Provider
        value={{
          appLocales,
          locale,
          updateLocale
        }}
      >
        {!!(messages) && (
          <IntlProvider locale={locale} messages={messages[locale]}>
            {Children.only(children)}
          </IntlProvider>
        )}
      </LanguageContext.Provider>
    </ErrorBoundary>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
