import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ErrorBoundary from '../../containers/ErrorBoundary';

export default function TranslatedMessage({ messageKey, id, defaultMessage, values }) {
  return (
    <ErrorBoundary componentKey="TranslatedMessage">
      <FormattedMessage messageKey={messageKey} id={id} defaultMessage={defaultMessage} values={values} />
    </ErrorBoundary>
  )
}

TranslatedMessage.propTypes = {
  messageKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
};
