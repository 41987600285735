import PropTypes from 'prop-types';
import ErrorBoundary from '../ErrorBoundary';
import AppSettingsContext from './AppSettingsContext';

export default function AppSettingsProvider({ children }) {
  return (
    <ErrorBoundary componentKey="AppSettingsProvider">
      <AppSettingsContext.Provider
        value={{
          appVersion: process.env.REACT_APP_VERSION,
          environment: process.env.REACT_APP_ENVIRONMENT,
          appRoot: process.env.PUBLIC_URL
        }}
      >
        {children}
      </AppSettingsContext.Provider>
    </ErrorBoundary>
  );
}

AppSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
}
