import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Menu, MenuItem } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/arrow-up.svg';
import { ReactComponent as InfoIcon } from '../../assets/information-circle-2.svg';
import './style.scss';

export default function Dropdown({
  label,
  info,
  options,
  hasSmallWidth,
  hasSmallHeight,
  onChangeItem,
  defaultOption
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(defaultOption || options[0]);
  const open = Boolean(anchorEl);
  const width = hasSmallWidth ? '188px' : '392px';
  const height = hasSmallHeight ? '32px' : '36px';

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    onChangeItem(option);
    setAnchorEl(null);
  };

  return (
    <div style={{ width }} className="dropdown-wrapper">
      <Box className="label-container">
        <Typography className="dropdown-label">{label}</Typography>
        {info && <InfoIcon className="dropdown-info" />}
      </Box>
      <Button
        className="dropdown-button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ height }}
      >
        <span>{selectedOption.label}</span>
        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="dropdown-list"
      >
        {options.map((option, index) => (
          <MenuItem
            sx={{ width }}
            key={index}
            selected={option.value === selectedOption.value}
            className="dropdown-item"
            onClick={() => handleMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  info: PropTypes.string,
  hasSmallWidth: PropTypes.bool,
  hasSmallHeight: PropTypes.bool,
  onChangeItem: PropTypes.func,
  defaultOption: PropTypes.object
};
