import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ReactComponent as ArrowDownBoldIcon } from '../../assets/arrow-down-bold.svg';
import { ReactComponent as ArrowUpBoldIcon } from '../../assets/arrow-up-bold.svg';
import './style.scss';

export default function PanelSection({ title, children }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="panel-section-wrapper">
      <Box className="section-header">
        <Typography variant="subtitle1" className="section-title">
          {title}
        </Typography>
        {isOpen ? (
          <ArrowUpBoldIcon
            className="section-arrow"
            onClick={() => setIsOpen(false)}
          />
        ) : (
          <ArrowDownBoldIcon
            className="section-arrow"
            onClick={() => setIsOpen(true)}
          />
        )}
      </Box>
      {isOpen && children}
    </div>
  );
}

PanelSection.propTypes = {
  title: PropTypes.string.isRequired,
};
