import PanelSection from '../PanelSection';
import SearchBar from '../SearchBar';
import './style.scss';

export default function MetersPanel() {
  return (
    <div className="meters-panel-wrapper">
      <PanelSection title="Meters">
        <div className="panel-row">
          <SearchBar
            onSearchChange={(keyword) =>
              console.log(`Searched for: ${keyword}`)
            }
          />
        </div>
      </PanelSection>
    </div>
  );
}
