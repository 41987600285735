import PropTypes from 'prop-types';
import './style.scss';
import TranslatedMessage from '../TranslatedMessage';
import LanguageSelector from '../LanguageSelector';
import messages from './messages';

export default function Layout({ left, right }) {
  return (
    <div className="layout-wrapper">
      <div className="side-panel">{left}</div>
      <div className="content">{right}</div>
      <TranslatedMessage messageKey="Layout.dummyText" {...messages.dummy} />
      <LanguageSelector />
    </div>
  );
}

Layout.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
};
