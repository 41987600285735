import { useState } from 'react';
import ErrorBoundary from './containers/ErrorBoundary';
import AppSettingsProvider from './containers/AppSettingsProvider';
import LanguageProvider from './containers/LanguageProvider';
import Layout from './components/Layout';
import SidePanel from './components/SidePanel';

function App() {
  const [content, setContent] = useState({});
  return (
    <ErrorBoundary componentKey="App">
      <AppSettingsProvider>
        <LanguageProvider>
          <Layout
            left={<SidePanel setContent={setContent} />}
            right={<>
              <h2 style={{ textAlign: 'center' }}>{content.selectedView}</h2>
              <h3 style={{ textAlign: 'center' }}>{content.selectedViewOptionsItem}</h3>
            </>} />
        </LanguageProvider>
      </AppSettingsProvider>
    </ErrorBoundary>
  );
}

export default App;
