import { useState } from 'react';
import PropTypes from 'prop-types';
import { viewSelectorData } from '../../static'
import Dropdown from '../Dropdown';
import ViewOptionsPanel from '../ViewOptionsPanel';
import MetersPanel from '../MetersPanel';
import './style.scss';
const { views, viewsConfig } = viewSelectorData;

export default function SidePanel({ onDataChanged }) {
  // All compiled data of the entire side panel to be used when creating the payload.
  // To be set with the default data for the default view of the page - potentially set by the child elements of this page.
  const sidePanelData = {};

  const [selectedView, setSelectedView] = useState(views.data[0]);
  const [selectedViewConfig, setSelectedViewConfig] = useState(viewsConfig.single);

  const updateView = (viewOption) => {
    setSelectedView(viewOption);
    setSelectedViewConfig(viewsConfig[viewOption.value]);
  }

  const onSelectedViewOptionsItem = (viewOptionsData) => {
    sidePanelData.viewOptionsData = viewOptionsData;
    // onDataChanged(sidePanelData);
  }

  return (
    <div className="side-panel-wrapper">
      <Dropdown
        options={views.data}
        defaultOption={selectedView}
        label={'View'}
        onChangeItem={(option) => updateView(option)}
      />

      <ViewOptionsPanel
        viewOptions={selectedViewConfig.viewOptions}
        onViewOptionsChange={onSelectedViewOptionsItem}
      />

      <MetersPanel />
    </div>
  );
}

SidePanel.propTypes = {
  onDataChanged: PropTypes.func
};
