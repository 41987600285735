import { useContext } from 'react';
import ErrorBoundary from '../../containers/ErrorBoundary';
import LanguageContext from '../../containers/LanguageProvider/LanguageContext';

export default function LanguageSelector() {
  const { appLocales, locale, updateLocale } = useContext(LanguageContext);

  return (
    <ErrorBoundary componentKey="LanguageSelector">
      <select onChange={(event) => updateLocale(event.target.value)}>
        {appLocales.map((localeItem) => (
          <option key={localeItem} value={localeItem} selected={localeItem === locale}>{localeItem.toUpperCase()}</option>
        ))}
      </select>
    </ErrorBoundary>
  );
}
