import filter from 'lodash-es/filter';
// @todo: the actual values below are subject to change depending on the actual API data structure

// defaults
const chartTypeDefaultData = [
  {
    value: 'bar',
    label: 'Bar Graph'
  },
  {
    value: 'line',
    label: 'Line Graph'
  },
  {
    value: 'pie',
    label: 'Pie Chart'
  },
  {
    value: 'barPlusLine',
    label: 'Bar + Line Graph'
  },
  {
    value: 'spectral',
    label: 'Spectral Analysis'
  }
];
const intervalDefaultData = [
  {
    value: 'minimum',
    label: 'Minimum'
  },
  {
    value: 'day',
    label: 'Day'
  },
  {
    value: 'month',
    label: 'Month'
  }
];
const unitConversionDefaultData = [
  {
    value: 'noConversion',
    label: 'No Conversion'
  }
];
const emissionsCostDefaultData = [
  {
    value: 'noConversion',
    label: 'No Conversion'
  },
  {
    value: 'currency',
    label: 'Currency'
  },
  {
    value: 'emissions',
    label: 'Emissions'
  }
];
const normalizationDefaultData = [
  {
    value: 'noNormalization',
    label: 'No Normalization'
  },
  {
    value: 'baggageCount',
    label: 'Baggage Count'
  },
  {
    value: 'buildingAreaMeters',
    label: 'Building Area (m2)'
  },
  {
    value: 'buildingAreaFeet',
    label: 'Building Area (sq ft)'
  },
  {
    value: 'coolingDegreeDays',
    label: 'Cooling Degree Days'
  },
  {
    value: 'dynamicOccupancy',
    label: 'Dynamic Occupancy'
  },
  {
    value: 'fixedOccupancy',
    label: 'Fixed Occupancy'
  },
  {
    value: 'flowRate',
    label: 'Flow Rate'
  },
  {
    value: 'heatingDegreeDays',
    label: 'Heating Degree Days'
  },
  {
    value: 'lightLuxLevel',
    label: 'Light Lux Level'
  },
  {
    value: 'passangerCount',
    label: 'Passanger Count'
  },
  {
    value: 'temperature',
    label: 'Temperature'
  },
];

const viewSelectorData = {
  views: {
    data: [
      {
        value: 'single',
        label: 'Single Meter View'
      },
      {
        value: 'meterComparison',
        label: 'Meter Comparison View'
      },
      {
        value: 'timePeriodComparison',
        label: 'Time Period Comparison View'
      },
      {
        value: 'correlation',
        label: 'Correlation with Driving Factor'
      },
      {
        value: 'usageVsTarget',
        label: 'Usage vs Target'
      },
      {
        value: 'overspend',
        label: 'Overspend'
      },
      {
        value: 'overspendCusum',
        label: 'Overspend CUSUM'
      },
      {
        value: 'energyUsageCusum',
        label: 'Energy Usage CUSUM'
      },
      {
        value: 'twelveMonthsView',
        label: 'Rolling 12 Months View'
      }
    ]
  },
  viewsConfig: {
    single: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line', 'spectral'].includes(item.value)),
          selected: 'bar',
        },
        interval: {
          data: filter(intervalDefaultData, (item) => ['minimum'].includes(item.value)),
        },
        unitConversion: {
          data: unitConversionDefaultData,
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        },
        normalization: {
          data: filter(normalizationDefaultData, (item) => ['noNormalization', 'buildingAreaMeters', 'buildingAreaFeet', 'coolingDegreeDays', 'dynamicOccupancy', 'fixedOccupancy', 'heatingDegreeDays', 'lightLuxLevel', 'temperature'].includes(item.value)),
          selected: 'noNormalization',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    meterComparison: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line', 'pie', 'barPlusLine'].includes(item.value)),
          selected: 'line',
        },
        interval: {
          data: filter(intervalDefaultData, (item) => ['minimum', 'day'].includes(item.value)),
          selected: 'minimum',
        },
        unitConversion: {
          data: unitConversionDefaultData
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        },
        normalization: {
          data: normalizationDefaultData,
          selected: 'noNormalization',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    timePeriodComparison: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line', 'pie', 'barPlusLine'].includes(item.value)),
          selected: 'line',
        },
        interval: {
          data: filter(intervalDefaultData, (item) => ['minimum', 'day'].includes(item.value)),
          selected: 'minimum',
        },
        unitConversion: {
          data: unitConversionDefaultData
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        },
        normalization: {
          data: normalizationDefaultData,
          selected: 'noNormalization',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    correlation: {
      viewOptions: {},
      calendarOptions: {},
      meterOptions: {},
    },
    usageVsTarget: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line', 'pie', 'barPlusLine'].includes(item.value)),
          selected: 'barPlusLine',
        },
        interval: {
          data: intervalDefaultData,
          selected: 'day',
        },
        unitConversion: {
          data: unitConversionDefaultData,
          selected: 'noConversion',
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    overspend: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line'].includes(item.value)),
          selected: 'bar',
        },
        interval: {
          data: filter(intervalDefaultData, (item) => ['day'].includes(item.value))
        },
        unitConversion: {
          data: unitConversionDefaultData,
          selected: 'noConversion',
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    overspendCusum: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line'].includes(item.value)),
          selected: 'line',
        },
        interval: {
          data: filter(intervalDefaultData, (item) => ['day'].includes(item.value))
        },
        unitConversion: {
          data: unitConversionDefaultData,
          selected: 'noConversion',
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    energyUsageCusum: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line'].includes(item.value)),
          selected: 'line',
        },
        unitConversion: {
          data: unitConversionDefaultData,
          selected: 'noConversion',
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    },
    twelveMonthsView: {
      viewOptions: {
        chartType: {
          data: filter(chartTypeDefaultData, (item) => ['bar', 'line', 'barPlusLine'].includes(item.value)),
          selected: 'barPlusLine',
        },
        interval: {
          data: filter(intervalDefaultData, (item) => ['month'].includes(item.value))
        },
        unitConversion: {
          data: unitConversionDefaultData,
          selected: 'noConversion',
        },
        emissionsCost: {
          data: filter(emissionsCostDefaultData, (item) => ['noConversion', 'currency', 'emissions'].includes(item.value)),
          selected: 'noConversion',
        },
        normalization: {
          data: normalizationDefaultData,
          selected: 'noNormalization',
        }
      },
      calendarOptions: {},
      meterOptions: {},
    }
  }
}

export default viewSelectorData;
